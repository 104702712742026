<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        
        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body ">

                <div class="header_section">
                  <h4 class="menu_header">Shipping Options</h4>
                  <div class="">
                    <router-link :to="{ name: 'createshippingoptions'}">
                      <button class="btn btn-default custom-orange">
                        <i class="fa fa-plus"></i> New Shipping Option
                      </button>
                    </router-link>
                  </div>
                </div>
               
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Date Added</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="(shippingOption, mindex) in responseData" :key="mindex">
                        <td>{{parseInt(mindex)+1}}.</td>
                        <td>
                          <router-link :to="{ name: 'updateshippingoptions', params: { id:shippingOption.id }}">
                            {{shippingOption.shipping_type == 'flat' ? 'Internal delivery' : shippingOption.shipping_type.charAt(0).toUpperCase() + shippingOption.shipping_type.slice(1)}}
                          </router-link> 
                        </td>
                        <td>{{formatDate(shippingOption.created_at)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div v-if="loading"  class="loading-cover">
                  <div class="loader"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
// import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
// const toast = useToast();
export default {
    data() {
        return {
            shippingOptions: [],
            loading:false,
            responseData: null
            
        }
    },
    beforeMount: async function () {
        this.getShippingOptions();
    },
    methods: {
        async getShippingOptions(){
            this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/shipping-options/" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200 ) {
                    this.loading = false
                    this.responseData = response.data.data
                }
            }).catch((error) => {
                this.loading = false;
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            });
            // 
        }
    },
    mixins: [ReusableDataMixins,ReusableFunctionMixins]
}
</script>
